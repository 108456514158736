














import Vue from 'vue'
import UserForm from '@/apps/FNM/components/UserForm.vue'
import User from '../../../../store/models/User'
import { FNM_ROLES } from '@/constants'

export default Vue.extend({
  components: {
    UserForm
  },

  data() {
    return {
      active: false,
      loading: false,
      roles: FNM_ROLES,
      model: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        role: ''
      }
    }
  },

  computed: {
    userId(): string {
      return this.$route.params.userId
    },

    user(): User {
      return User.find(this.userId) as User
    }
  },

  mounted() {
    this.active = true
    if (this.user) {
      this.model = {
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        email: this.user.email,
        phone: this.user.phone,
        role: this.user.role
      }
    }
  },

  methods: {
    onEdit(this: any, user: any) {
      this.loading = true
      User.api()
        .update(this.userId, user)
        .then(() => {
          this.loading = false
          this.active = false
          this.$notify.success("L'utilisateur a été modifié avec succès !")
        })
        .catch((error: any) => {
          this.loading = false

          if (error.response && error.response.status === 400) {
            this.$refs.form.setErrors(error.response.data.message)
          }

          this.$notify.error("La modification de l'utilisateur à échouée !")
        })
    }
  }
})
